/* Values.css */

/* Container styles */
.values-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--edge-padding); /* Use global variable */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 8px;
}

/* Left container */
.right {
  flex:1;
}
.left {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* Image styles */
.image-container img {
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Individual value item */
.value-item {
  position: relative;
  margin-bottom: 20px;
}

/* Button styles */
.value-button {
  padding: 10px; /* Decrease padding */
  background-color: var(--landing-page-blue-color);
  color: #fff;
  text-align: center;
  text-decoration: none;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  width: 100%;
  font-size: var(--small-font-size); /* Decrease font size */
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Align title and preview text */
  height: 40px; /* Collapsed height */
  transition: height 0.3s ease, background-color 0.3s ease;
}

/* Darken button on hover and expand height */
.value-button:hover {
  background-color: #2c3e50;
  height: 100px; /* Expanded height on hover */
}

/* Title styles */
.value-button .title {
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis for long titles */
  white-space: nowrap;
}

/* Preview text styles */
.preview-text {
  padding: 10px;
  color: #fff;
  font-size: var(--small-font-size);
  font-family: var(--font-family);
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Show title even when collapsed */
.value-button .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/* Show preview text on button hover */
.value-button:hover .preview-text {
  opacity: 1;
}

.value-button .preview-text a {
  display: none;
}

/* Expand read more link on hover */
.value-item:hover .value-button .preview-text a {
  display: block;
}


@media (max-width: 900px) {
  .values-container {
    flex-direction: column; /* Change to a column layout */
    align-items: center; /* Center items in the column */
  }

  .right {
    order: -1; /* Move the image container to the top in the column layout */
    margin-bottom: 20px; /* Add some space between the image and buttons */
  }
}