/* SignupForm.css */

/* Global styles */
:root {
  --landing-page-blue-color: #007aff;
  --red_text-font-size: 2rem;
  --extra-small-font-size: 0.75rem;
  --small-font-size: 1rem;
  --medium-font-size: 1.5rem;
  --large-font-size: 2rem;
  --extra-large-font-size: 2.5rem;
  --extra-large-large-font-size: 4rem;
  --thin-font-weight: 1rem;
  --normal-font-weight: 2rem;
  --thick-font-weight: 3rem;
  --extra-thick-font-weight: 4rem;
  --small-edge-padding: 2.5rem;
  --edge-padding: 5rem;
  --edge-padding-mobile: 1.5rem;
  --font-family: 'IBM Plex Sans';
  --nav-height: 4rem;
}

* {
  box-sizing: border-box;
}

html {
  font-family: var(--font-family);
}

body {
  margin: 0;
}

/* Container styles */
.background-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 4rem 0;
  padding: 2rem;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup-container {
  max-width: 1200px;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: var(--font-family);
}

.signup-left,
.right {
  flex: 1;
  padding: var(--small-edge-padding);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.signup-left {
  align-items: flex-start;
}

.signup-left_item {
  margin: 0;
  font-size: var(--extra-large-large-font-size);
  font-weight: var(--extra-thick-font-weight);
  flex-wrap: wrap;
  display: inline-block !important;
  font-family: var(--font-family);
  padding-bottom: 1rem;
  color: #000000;
  transform: translateY(20px);
  animation: fadeInUp 0.8s forwards;
}

.form_title,
.form_description,
.right,
.form_container {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s forwards;
}

.form_container {
  background-color: #f4f4f4;
  margin: 0 4rem;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  max-width: 600px;
  box-sizing: border-box;
}

.form_title,
.form_description,
.form_input_field,
.form__bottom,
.form_input_para,
.button-signup {
  margin: 0;
}

.form_title {
  margin-top: 1rem;
  color: #333;
  font-size: var(--extra-large-font-size);
  font-weight: var(--normal-font-weight);
}

.form_description {
  color: #555;
  font-size: var(--small-font-size);
  font-weight: var(--thin-font-weight);
  margin-bottom: 0.5rem;
}

.form_input_field {
  color: #333;
  font-size: var(--small-font-size);
  font-weight: var(--normal-font-weight);
  padding: 1rem;
  margin-bottom: 0.5rem;
  border: 2px solid #ccc;
  border-radius: 8px;
  width: 100%;
}

.form_item {
  width: 100%;
  margin-bottom: 0;
}

.form__bottom {
  background-color: #555;
  color: #fff;
  font-size: 0.5rem;
  padding: 0.5rem;
  border-radius: 2px;
}

.button-signup {
  background-color: #007aff;
  color: #fff;
  font-size: var(--medium-font-size);
  font-weight: var(--thick-font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0.75rem;
}

.button-signup:hover {
  background-color: #0056b3;
}

@keyframes fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* ... (responsive design) ... */

/* ... (your existing code) ... */

@media (max-width: 768px) {
  :root {
    --small-edge-padding: 1.5rem; /* Adjusted edge padding for smaller screens */
    --edge-padding: 2.5rem; /* Adjusted edge padding for smaller screens */
    --edge-padding-mobile: 1rem; /* Adjusted edge padding for smaller screens */
    --extra-large-font-size: 2rem; /* Adjusted extra-large font size for smaller screens */
    --extra-large-large-font-size: 3rem; /* Adjusted extra-large-large font size for smaller screens */
    --normal-font-weight: 1.5rem; /* Adjusted normal font weight for smaller screens */
    --thick-font-weight: 2rem; /* Adjusted thick font weight for smaller screens */
    --medium-font-size: 1.25rem; /* Adjusted medium font size for smaller screens */
    --small-font-size: 1rem; /* Adjusted small font size for smaller screens */
  }

  .signup-container {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-top: var(--nav-height-mobile);
    margin-bottom: 40rem;
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .signup-left,
  .right {
    flex: 1;
    padding: var(--small-edge-padding);
    width: 100%;
    align-items: center;
  }

  .signup-left {
    order: -1;
    margin-bottom: 1rem;
    padding: 0rem;
    margin: 0rem;

  }

  .signup-left_item {
    text-align: center;
    margin: 0rem ;

    padding: 4rem;
    font-size: var(--extra-large-font-size);

    font-weight: var(--thin-font-weight);
    flex-wrap: wrap;
    display: inline-block !important;
    font-family: var(--font-family);
    padding-bottom: 1rem;
    color: #000000;
    transform: translateY(20px);
    animation: fadeInUp 0.8s forwards;
  }

  .form_container {
    margin: 1rem 0;
  }

  .form_title {
    margin-top: 2rem;
  }
}



