/* Add this to your existing About section CSS */

.about-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
    background-color: #ffffff;
    border-radius: 8px; /* Add rounded corners */
    transition: transform 0.3s ease-in-out;
}
.about-container:hover {
    transform: scale(1.02); /* Adjust the scale factor as needed */
}

.about-image-container {
    flex: 1;
}
.about-image-container:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a subtle shadow effect */
}

.about-image {
    width: 100%;
    max-width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-text-container {
    flex: 1;
    padding: 0 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-text-container:hover {
    background-color: #e0e0e0; /* Change background color on hover */
    transition: background-color 0.3s ease-in-out;
}

.about-para {
    width: 100%;
    color: #333333;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: var(--large-font-size);
    font-weight: bold;
    margin-bottom: 10px;
}

.about-description {
    color: #555555;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: var(--medium-font-size);
    line-height: 1.5;
    margin-bottom: 20px;
}

.about-description a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
    transition: color 0.3s ease-in-out;
}

.about-description a:hover {
    color: #0056b3;
}

@media screen and (max-width: 900px) {
    .about-text-container {
        padding: 20px;
        border-radius: 0;
    }
}

@media screen and (max-width: 900px) {
    .about-container {
        padding: 2rem;
        box-shadow: none; /* Remove box shadow on smaller screens */
        border-radius: 0; /* Remove rounded corners on smaller screens */
        margin-top: 15rem;
    }

    .about-image-container,
    .about-text-container {
        flex: 1;
        padding: 0;
    }

    .about-image {
        border-radius: 0;
    }

    .about-para {
        font-size: var(--large-font-size);
        font-weight: bold;
    }

    .about-description {
        font-size: var(--medium-font-size);
    }


    @media (max-width: 900px) {
        .about-container {
          flex-direction: column; /* Change to a column layout */
          align-items: center; /* Center items in the column */
        }
      
        .right {
          order: -1; /* Move the image container to the top in the column layout */
          margin-bottom: 20px; /* Add some space between the image and buttons */
        }
      }
}
