:root {
  --landing-page-blue-color: #007aff;
  --red_text-font-size: 2rem;
  --extra-small-font-size: 0.75rem;
  --small-font-size: 1rem;
  --medium-font-size: 1.5rem;
  --large-font-size: 2rem;
  --extra-large-font-size: 2.5rem;
  --extra-large-large-font-size: 4rem;
  --thin-font-weight: 1rem;
  --normal-font-weight: 2rem;
  --thick-font-weight: 3rem;
  --extra-thick-font-weight: 4rem;
  --small-edge-padding: 2.5rem;
  --edge-padding: 5rem;
  --edge-padding-mobile: 1.5rem;
  --font-family: 'IBM Plex Sans';
  --nav-height: 4rem;
  --nav-height-mobile: 60rem;
}

* {
  box-sizing: border-box;
}

html {
  font-family: var(--font-family);
}

body {
  margin: 0;
}
