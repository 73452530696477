/* body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif; 
  background-color: #fcfcfc;
} */

.containern {
  /* max-width: 1100px; */
  margin: 0rem 2rem 0rem 2rem;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
 
}

.navbar {
  height: 60px;
  background-color: #92DCFF;
  position: relative;
  width: 100%;
}

/* .logo {
} */
.logo {
  height: 2rem;
  /* width: 8rem; */
  background-size: cover;
  background-position: center;
  max-height: 100%;
  max-width: 100%;
}

.menu-icon {
  display: none;
}

.nav-elements {
}

.nav-elements ul {
  display: flex;
  height: 100%;
  margin-top: 0%;
  margin-bottom: 0%;
  justify-content: space-between;
  list-style-type: none;
  align-items: center;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: var(--small-font-size);
  font-weight: 400;
  color: #2f234f;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

/* @media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
} */

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;

    z-index: 1000;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #92DCFF;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
    z-index: 1000;
  }

  .nav-elements.active {
    width: 270px;
    z-index: 1000;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 1000;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
    z-index: 1000;
  }
}
/* 
  .main-header {
    width: 100%;
    height: var(--nav-height);
    position: fixed;
    top: 0;
    margin: 0;
    z-index: 60;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 var(--edge-padding-mobile);
    background-color: #92DCFF;
  }
  
  .nav-text {
    color: white;
    font-size: var(--small-font-size);
    font-weight: var(--small-font-weight);
    margin: 0;
    padding: 1rem;
    text-decoration: none;
    cursor: pointer;
    transition: color 0.3s ease-in-out; 
  }
  
  .logo-space {
    display: flex;
    flex-basis: 60%;
  }
  
  .logo {
    height: 8rem;
    width: 10rem;
    background-size: cover;
    background-position: center;
    max-height: 100%;
    max-width: 100%;
  }
  
  .about,
  .valu,
  .signup,
  .volunteer,
  .login {
    padding: 1rem; 
    display: none;
  
    @media screen and (min-width: 900px) {
      display: flex;
      flex-basis: 15%;
    }
  }
  
  .hamburger-menu {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  
    @media screen and (min-width: 900px) {
      display: none;
    }
  }
  
  .hamburger-line {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 6px 0;
    
  }
  .main-header.show .nav-text:hover {
    color: blue;
  }
  .main-header.show.nav-text {
    display:block;
  }
  
    @media screen and (max-width: 2920px) {
    .main-header {
      padding-left: var(--edge-padding);
      padding-right: var(--edge-padding);
    }
  
    .nav-text {
      font-size: var(--large-font-size);
      font-weight: var(--extra-thick-font-weight);
      padding: 1rem;
    }
  
    .logo {
        max-height: 3rem;
        max-width: 100%; 
    }

    .nav-text:hover {
      color: blue; 
    }
    
    .hamburger-line {
      transition: transform 0.3s ease-in-out;
    }
    
    .main-header.show .hamburger-line:nth-child(1) {
      transform: translateY(8px) rotate(-45deg);
    }
    
    .main-header.show .hamburger-line:nth-child(2) {
      transform: scale(0);
    }
    
    .main-header.show .hamburger-line:nth-child(3) {
      transform: translateY(-8px) rotate(45deg);
    }
    
  } */